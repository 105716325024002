import { Component, OnInit } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { Breadcrumb } from 'src/app/shared/models';
import { AbstractPage } from 'src/app/shared/pages/page.abstract';
import { SupportCenterSupportCallComponent } from '../modals/support-center-support-call/support-center-support-call.component';

@Component({
	selector: 'pk-broker-support-center',
	templateUrl: './support-center.component.html',
	styleUrls: ['./support-center.component.scss'],
})
export class SupportCenterComponent extends AbstractPage implements OnInit {
	public static breadcrumbs = [
		{
			text: 'Dashboard',
			route: '/contract',
		},
		{
			text: 'Support Center',
			isLast: true,
		},
	] as Breadcrumb[];

	public useReports: boolean;
	public useSupportSystem = true;
	public supportEmail = '';

	constructor(private modalService: BsModalService) {
		super(SupportCenterComponent.breadcrumbs, 'Support Center');
	}

	ngOnInit(): void {
		this.getSupplierCenterData();
	}

	private async getSupplierCenterData(): Promise<void> {
		this.useSupportSystem = true;
		this.supportEmail = '';
		if (this.loggedInUser.agent && this.loggedInUser.agent.parent) {
			this.useSupportSystem = this.loggedInUser.agent.useSupportSystem && this.loggedInUser.agent.parent.useSupportSystem;
			if (!this.useSupportSystem) {
				this.supportEmail = this.loggedInUser.agent.parent.supportEmail;
			}
		}

		this.loading = false;
	}

	public openSupportCallModal(): void {
		this.modalService.show(SupportCenterSupportCallComponent, {
			class: 'pk-modal pk-modal-support-center modal-dialog-centered',
		});
	}
}
