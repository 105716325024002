<pk-broker-modal customFooterClass="justify-content-center" [showFooter]="step === 2">
	<ng-template #headerTemplate>
		<h5 class="modal-title">{{ title }}</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<div class="container-fluid">
			<pk-broker-loader [show]="loading"></pk-broker-loader>
			<form [formGroup]="formGroup" *ngIf="!loading">
				<ng-container *ngIf="step === 1">
					<div class="row">
						<div class="col-12 modal-gray-area">
							<p>Are you encountering one of these issues?</p>
							<ul class="semibold-text">
								<li class="mb-3" *ngFor="let option of supportOptions">
									<a href="javascript:void(0)" (click)="chooseSupportOption(option)">{{ option.name }}</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<p>If not, then please select from one of these choices and schedule a call with our support team <em>(powered by Calendly)</em>.</p>
							<p class="semibold-text"><em>I need to direct support on...</em></p>
							<ul class="semibold-text">
								<li class="mb-3" *ngFor="let option of calendlyOptions">
									<a [href]="option.link" target="_blank" (click)="activeModal.hide()">{{ option.name }}</a>
								</li>
							</ul>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="step === 2">
					<div class="row">
						<div class="col-12">
							<p class="availability-alert" [ngClass]="availability.class">{{ availability.text }}</p>
						</div>
					</div>
					<div class="row">
						<div
							class="col-12 form-element-container"
							pk-broker-form-element
							[parentForm]="formGroup"
							key="contractNum"
							name="Contract number relating to this issue:"
							customRequiredErrorMessage="Please enter a contract number."
							required></div>
					</div>
					<div class="row">
						<div
							class="col-12 form-element-container"
							pk-broker-form-element
							[parentForm]="formGroup"
							key="description"
							name="Give us an idea of your issue so we can better help you:"
							customRequiredErrorMessage="Please enter a description of your issue."
							required
							[isTextArea]="true"
							rows="5"></div>
					</div>
					<div class="row">
						<div
							class="col-12 form-element-container"
							pk-broker-form-element
							[parentForm]="formGroup"
							key="phone"
							name="Number to reach you at:"
							customRequiredErrorMessage="Please enter a valid phone number."
							required
							maxlength="15"
							[maskConfig]="{ mask: CONSTANTS.masks.phone }"></div>
					</div>
				</ng-container>
				<ng-container *ngIf="step === 3">
					<div class="row">
						<div class="col-12">
							<p>Thank you. Customer Support will call you {{ availability.confirmationEnding }}</p>
							<p>You may close this window.</p>
						</div>
					</div>
				</ng-container>
			</form>
		</div>
	</ng-template>
	<ng-template #footerTemplate>
		<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
	</ng-template>
</pk-broker-modal>
